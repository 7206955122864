<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>
        <router-link to="/admin/users">Mitarbeiter</router-link>
        <p>{{ user.last_name }}, {{ user.first_name }}</p>
      </h1>
    </header>

    <div class="row row-gutter-20">
      <div class="col-8">
        <div class="content-wrap">
          <h3>Mitarbeiterdaten</h3>

          <div class="form-wrap">
            <label for="first_name" class="form-label">Vorname</label>
            <input v-model="user.first_name" class="form-input input-grey" type="text" name="first_name" id="first_name">
          </div>

          <div class="form-wrap">
            <label for="last_name" class="form-label">Nachname</label>
            <input v-model="user.last_name" class="form-input input-grey" type="text" name="last_name" id="last_name">
          </div>

          <div class="form-wrap">
            <label for="phone_number" class="form-label">Telefon</label>
            <input v-model="user.phone_number" class="form-input input-grey" type="text" name="phone_number" id="phone_number">
          </div>

          <div class="form-wrap">
            <label for="email" class="form-label">E-Mail</label>
            <input v-model="user.email" class="form-input input-grey" type="text" name="email" id="email">
          </div>

          <div class="form-wrap select-wrap">
            <label for="role">Rolle</label>
            <select v-model="user.role" class="form-input">
              <option key="staff" value="staff">Mitarbeiter</option>
              <option key="administrator" value="administrator">Administrator</option>
            </select>
          </div>

          <p style="margin-bottom:0;text-align:right">
            <a @click="update_user" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="floppy-disk" class="svg-inline--fa fa-floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z"></path>
              </svg>
              <span>Daten speichern ›</span>
            </a>
          </p>

        </div>
      </div>
      <div class="col-8">
        <div class="content-wrap">
          <h3>Schalter</h3>
          <div v-if="user.counters && user.counters.length == 0" class="alert alert-info" style="margin-bottom:0">
            <p>Noch keine Schalter hinterlegt.</p>
          </div>
          <div v-else>
            <ul class="content-list">
              <li v-for="counter in user.counters" v-bind:key="counter.id">
                {{ counter.name }}

                <svg @click="delete_counter(counter)" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                </svg>
              </li>
            </ul>
          </div>
          <p style="text-align:right;margin-bottom:0">
            <a @click="modal_add_counters = true" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
              </svg>
              <span>Schalter hinzufügen ›</span>
            </a>
          </p>
        </div>
        <div class="content-wrap">
          <h3>Standorte</h3>
          <div v-if="user.locations && user.locations.length == 0" class="alert alert-info" style="margin-bottom:0">
            <p>Noch kein Standort hinterlegt.</p>
          </div>
          <div v-else>
            <ul class="content-list">
              <li v-for="location in user.locations" v-bind:key="location.id">
                {{ location.name }}

                <svg @click="delete_location(location)" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                </svg>
              </li>
            </ul>
          </div>
          <p v-if="user.locations && user.locations.length == 0" style="text-align:right;margin-bottom:0">
            <a @click="modal_add_locations = true" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
              </svg>
              <span>Standort hinzufügen ›</span>
            </a>
          </p>
        </div>
      </div>
      <div class="col-8">
        <div class="content-wrap">
          <h3>Arbeitszeiten</h3>
          <div v-if="working_hours && working_hours.length == 0" class="alert alert-info" style="margin-bottom:0">
            <p>Noch keine Arbeitszeiten hinterlegt.</p>
          </div>
          <div v-else>
            <ul class="content-list">
              <li v-for="hour in working_hours" v-bind:key="hour.id">
                <span v-if="hour.working_day == 'monday'">Montag:</span>
                <span v-if="hour.working_day == 'tuesday'">Dienstag:</span>
                <span v-if="hour.working_day == 'wednesday'">Mittwoch:</span>
                <span v-if="hour.working_day == 'thursday'">Donnerstag:</span>
                <span v-if="hour.working_day == 'friday'">Freitag:</span>
                <span v-if="hour.working_day == 'saturday'">Samstag:</span>
                <span v-if="hour.working_day == 'sunday'">Sonntag:</span>
                {{ hour.start_formatted }} Uhr – {{ hour.end_formatted }} Uhr

                <svg @click="delete_hour(hour)" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
                </svg>
              </li>
            </ul>
          </div>
          <p style="text-align:right;margin-bottom:0">
            <a @click="modal_add_working_hour = true" class="button button-red">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path fill="currentColor" d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"></path>
              </svg>
              <span>Arbeitszeit hinzufügen ›</span>
            </a>
          </p>
        </div>
      </div>
    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_working_hour">
      <svg @click="modal_add_working_hour = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Arbeitszeit hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="working_day">Wochentag</label>
          <select v-model="working_hour.working_day" class="form-input">
            <option key="monday" value="monday">Montag</option>
            <option key="tuesday" value="tuesday">Dienstag</option>
            <option key="wednesday" value="wednesday">Mittwoch</option>
            <option key="thursday" value="thursday">Donnerstag</option>
            <option key="friday" value="friday">Freitag</option>
            <option key="saturday" value="saturday">Samstag</option>
            <option key="sunday" value="sunday">Sonntag</option>
          </select>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="start" class="form-label">Von</label>
              <input v-model="working_hour.start" class="form-input input-grey" type="time" name="start" id="start">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="end" class="form-label">Bis</label>
              <input v-model="working_hour.end" class="form-input input-grey" type="time" name="end" id="end">
            </div>
          </div>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_working_hour" class="button button-red button-100">Arbeitszeit erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_counters">
      <svg @click="modal_add_counters = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Schalter hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap">
          <label for="name" class="form-label">Schalter</label>
          <select v-model="counter.counters" multiple  class="form-input">
            <option v-for="counter in counters" v-bind:key="counter.id" v-bind:value="counter.id">{{ counter.name }}</option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_counter" class="button button-red button-100">Schalter hinzufügen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_locations">
      <svg @click="modal_add_locations = false" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"></path>
      </svg>
      <div class="modal-head">
        <h3>Standort hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap">
          <label for="name" class="form-label">Standort</label>
          <select v-model="location.location_id"  class="form-input">
            <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">{{ location.name }}</option>
          </select>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_location" class="button button-red button-100">Standort hinzufügen ›</a>
      </div>
    </vue-final-modal>

  </div>
</template>

<script>
export default {
  name: 'admin_user',
  data () {
    return {
      modal_add_working_hour: false,
      modal_add_counters: false,
      modal_add_locations: false,
      user: {},
      working_hours: [],
      working_hour: {},
      counters: [],
      counter: {},
      locations: [],
      location: {}
    }
  },
  methods: {
    async get_data() {
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid+'/working_hours', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.working_hours = response.data.working_hours;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/counters', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.counters = response.data.counters;
      })
      await this.$http.get(process.env.VUE_APP_BASE_API+'/admin/locations', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.locations = response.data.locations;
      })
    },
    update_user() {
      this.$http.patch(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid, this.user, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.$notify({
          title: "Erfolgreich gespeichert.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    create_working_hour() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid+'/working_hours', this.working_hour, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.modal_add_working_hour = false;
        this.working_hour = {};
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    delete_hour(hour) {
      if(confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid+'/working_hours/'+hour.id,  { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gelöscht.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
      }
    },
    delete_counter(counter) {
      if(confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid+'/delete_counter/'+counter.id,  { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gelöscht.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
      }
    },
    delete_location(location) {
      if(confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid+'/delete_location/'+location.id,  { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gelöscht.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
      }
    },
    create_counter() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid+'/counters', this.counter, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.modal_add_counters = false;
        this.counter = {};
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    },
    create_location() {
      this.$http.post(process.env.VUE_APP_BASE_API+'/admin/users/'+this.$route.params.uuid+'/locations', this.location, { headers: { Authorization: this.$store.getters.get_token } })
      .then(() => {
        this.get_data();
        this.modal_add_locations = false;
        this.location = {};
        this.$notify({
          title: "Erfolgreich erstellt.",
          type: "success"
        });
      })
      .catch(error => {
        error.response.data.errors.forEach(value => {
          this.$notify({
            title: value,
            type: "error"
          });
        });
      });
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>
